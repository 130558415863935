<template>
  <section>
    <div class="text-center f16">{{ fullExam ? $t('Exam')+ " " + fullExam.name : '' }}</div>
    <v-progress-linear :active="loadingBarState" :indeterminate="loadingBarState" absolute bottom
      color="light-blue accent-4"></v-progress-linear>
    <div class="d-flex flex-column justify-content-center">
      <div v-if="fullExam" class="d-flex flex-column justify-content-center ">
        <div class="pa-2">
          {{$t('Number of questions:')}} {{ fullExam.questions.length }}
        </div>
        <div class="pa-2">
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header><span class="f14 text-justify">{{$t('Exam description')}}</span></v-expansion-panel-header>
              <v-expansion-panel-content>
                <p style="text-align: justify;" v-html="fullExam.drDescription"></p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="pa-2">
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header><span class="f14 text-justify">{{$t('Questions Preview')}}</span></v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="pa-2">
                  <v-expansion-panels>
                    <v-expansion-panel v-for="question in fullExam.questions" :key="question.id">
                      <v-expansion-panel-header>
                        <span class="f13 text-justify">{{ question.number }}. {{ question.title }}</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-show="question.type === 'NORMAL'">
                          <ol>
                            <li class="text-lg-right" v-for="item in question.items" :key="item.id">
                              {{ item.title }}
                            </li>
                          </ol>
                        </div>
                        <div v-show="question.type === 'LIKERT'">
                          <v-slider class="mt-8" :thumb-size="24" thumb-label="always" ticks="always" tick-size="8" dense hide-details
                            :min="question.likertItem ? question.likertItem.startFrom : undefined"
                            :max="question.likertItem ? question.likertItem.endTo : undefined" />
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <v-flex class="text-center" v-if="!fullExam && !loadingBarState">
        {{$t("YOU DON'T HAVE PERMISSION...")}}
      </v-flex>
    </div>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";

export default {
  data() {
    return {
      fullExam: undefined,
      examState: false,
      loadingBarState: false,
    };
  },
  methods: {
    getFullExam(id) {
      apiService.examPreview(id)
        .then((response) => {
          this.fullExam = response.data;
          this.fullExam.questions = this.fullExam.questions.sort(this.sortByNumber);
          this.fullExam.questions.forEach(q => q.items = q.items.sort(this.sortByNumber));
          this.examState = true;
          // setTimeout(() => {            
          //   let images = document.getElementsByTagName("img");
          //   let imagePromises = [];
          //   for (let image of images) {
          //     image.setAttribute('crossOrigin', 'anonymous');
          //     // image.src = image.src.replace('https', 'http')
          //   }
          //   Promise.all(imagePromises);
          // }, 5000);

        })
        .catch((err) => {
          this.examState = false
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });
        })
        .finally(() => {
          this.loadingBarState = false
        })
    },
    sortByNumber(a, b) {
      if (a.number < b.number) return -1;
      if (a.number > b.number) return 1;
      return 0;
    }
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  mounted() {
    this.loadingBarState = true
    this.getFullExam(this.$route.params.examId)

  },
}
</script>


<style>
img {
  width: 100%;
  max-width: 100%;
  height: auto;

}
</style>
